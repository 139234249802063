<template>
    <div>
          <app-layout>
              <template v-slot:header>
                  <Header :title="$t('my_observer')"
                          :isColumns="true"
                          :isFilter="false"
                       >
                      <template v-slot:columns>
                          <div class="mb-1" v-for="(column,key) in datatable.columns">
                              <b-form-checkbox
                                  :id="'checkbox-'+key"
                                  :name="'checkbox-'+key"
                                  :value="false"
                                  :unchecked-value="true"
                                  v-model="column.hidden"
                                  v-if="column.field!='buttons'"
                              >
                                  {{ column.label }}
                              </b-form-checkbox>
                          </div>
                      </template>
                  </Header>
              </template>
              <template v-slot:header-mobile>
                  <HeaderMobile :title="$t('classroom')"
                                :isColumns="true"
                            >
                      <template v-slot:columns>
                          <div class="mb-1" v-for="(column,key) in datatable.columns">
                              <b-form-checkbox
                                  :id="'checkbox-'+key"
                                  :name="'checkbox-'+key"
                                  :value="false"
                                  :unchecked-value="true"
                                  v-model="column.hidden"
                                  v-if="column.field!='buttons'"
                              >
                                  {{ column.label }}
                              </b-form-checkbox>
                          </div>
                      </template>
                  </HeaderMobile>
              </template>

              <datatable :isLoading.sync="datatable.isLoading"
                         :columns="datatable.columns"
                         :rows="datatable.rows"
                         :total="datatable.total"
                         :queryParams="datatable.queryParams"
                         @on-page-change="onPageChange"
                         @on-sort-change="onSortChange"
                         @on-per-page-change="onPerPageChange"
                         v-show="datatable.showTable"
              >
              </datatable>
          </app-layout>
    </div>
</template>
<script>
//components
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
//services
import TeacherClassroomFilterService from "@/services/TeacherClassroomFilterService";




export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,

    },
    metaInfo() {
        return {
            title: this.$t('classroom')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [

                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase('course_code'),
                        field: ('course_code'),
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('course_name'),
                        field: ('course_name'),
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('classroom'),
                        field: ('classroom'),
                        hidden: false,
                    },


                    {
                        label: this.toUpperCase('instructor'),
                        field: ('instructor'),
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('start_time'),
                        field: ('start_time'),
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('end_time'),
                        field: ('end_time'),
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('date'),
                        field: ('date'),
                        hidden: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'code',
                    page: 1,
                    limit: -1
                }
            },
        }
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {

            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return TeacherClassroomFilterService.getAll()
                .then((response) => {
                    this.datatable.rows = response.data.data
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
    },
    created() {
       this.getRows()
    }
}

</script>
